<template>
  <q-card class="row bg-transparent" flat style="width: 100%">
    <div v-for="link of links" class="col-4">
      <q-item class="q-mt-xs q-pt-xs q-mb-xs q-pb-xs">
        <q-input v-model="dataObject[link.platform]"
                 :label="link.title"
                 :rules="[val => $validators.isValidUrl(val) || !val || 'Неверная ссылка']"
                 class="full-width"
                 clearable
                 dense
                 outlined
                 type="url"/>
      </q-item>
    </div>
  </q-card>
</template>

<script>
import {
  defineComponent,
  ref,
  watch,
} from 'vue';
import { CONFIG } from '@/config';

export default defineComponent({
  props: ['modelValue'],
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const dataObject = ref({});
    let skipWatch = false;

    watch(() => props.modelValue,
      () => {
        if (skipWatch) {
          skipWatch = false;
          return;
        }

        dataObject.value = props.modelValue.reduce((acc, {
          platform,
          url,
        }) => {
          acc[platform] = url;
          return acc;
        }, {});
      });

    watch(dataObject,
      () => {
        const { value } = dataObject;

        Object.keys(value)
          .forEach((key) => {
            if (!value[key]) delete value[key];
          });

        skipWatch = true;
        emit('update:modelValue', Object.keys(value)
          .map((key) => ({
            platform: key,
            url: value[key],
          })));
      },
      { deep: true });

    return {
      dataObject,
      links: CONFIG.form.links,
    };
  },
});
</script>

<style>

</style>
