<template>
  <q-page class="q-pa-sm">
    <div class="row">
      <div class="col-12">
        <q-item>
          <q-input v-model="entity.name"
                   :rules="[val => $validators.required(val)]"
                   class="full-width"
                   clearable
                   dense
                   label="Название"
                   outlined/>
        </q-item>
      </div>
      <div class="col-12">
        <q-item>
          <q-input v-model="entity.description" autogrow class="full-width" clearable dense
                   label="Описание"
                   outlined/>
        </q-item>
      </div>

      <div class="col-12">
        <q-item>
          <image-uploader v-model="entity.logo_url"
                          :uploadMethod="file => new FtpApi().ftpControllerUploadImage(file, 'organizations')"
                          class="full-width"
                          label="Лого (PNG)"/>
        </q-item>
      </div>

      <links-form v-model="entity.external"></links-form>
    </div>

    <div class="q-pa-md">
      <q-btn :loading="loading" class="q-mr-md"
             color="primary"
             icon="save"
             label="Сохранить"
             rounded
             @click="saveEntity"/>

      <q-btn class="q-mr-md"
             icon="cancel"
             label="Назад"
             rounded
             @click="$router.back()"/>
    </div>
  </q-page>
</template>

<script>
import { AdminApi } from '@/api/apis/admin-api.ts';
import { FtpApi } from '@/api/apis/ftp-api.ts';
import { OrganizationApi } from '@/api/apis/organization-api.ts';
import ImageUploader from '@/components/forms/imageUploader';
import LinksForm from '@/components/forms/linksForm';
import { Notify } from 'quasar';
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default defineComponent({
  components: {
    LinksForm,
    ImageUploader,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const entityId = ref(route.params.id);
    const entity = ref({
      name: '',
      description: '',
      logo_url: '',
      account_no: 0,
      external: [],
    });

    if (entityId.value) {
      new OrganizationApi().organizationControllerGetOrganization(entityId.value)
        .then(({ data }) => {
          entity.value = data;
        })
        .catch(() => entityId.value = 0);
    }

    const loading = ref(false);
    return {
      FtpApi,
      AdminApi,
      OrganizationApi,
      entity,
      entityId,
      loading,

      async saveEntity() {
        loading.value = true;
        try {
          delete entity.value.account_no;

          const body = {
            name: entity.value.name,
            description: entity.value.description,
            logo_url: entity.value.logo_url,
            external: entity.value.external,
          };

          if (entityId.value) {
            if (store.getters.isAdmin) {
              await new AdminApi().partnerControllerUpdatePartner({
                ...body,
                account_no: entityId.value,
              });
            } else {
              await new OrganizationApi().organizationControllerUpdateOrganization(
                body,
                entityId.value,
              );
            }
          } else {
            const { data } = await new AdminApi().partnerControllerCreatePartner(body);
            if (data?.account_no) {
              entityId.value = data?.account_no;
            }
          }

          Notify.create({
            type: 'positive',
            message: 'Сохранено',
          });
        } catch (e) {
          console.error(e);
        }

        loading.value = false;
      },
    };
  },
});
</script>

<style>

</style>
