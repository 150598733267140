/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * Доброрубль JSON REST API
 *
 * OpenAPI spec version: 2.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '@/api';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ForbiddenException } from '../models';
import { NotFoundException } from '../models';
import { OrganizationResponseDto } from '../models';
import { OrganizationSearchResponseDto } from '../models';
import { UpdateOrganizationPayloadDto } from '../models';
import { UpdatePartnerResponseDto } from '../models';
import { UploadImageResponseDto } from '../models';

/**
 * OrganizationApi - axios parameter creator
 * @export
 */
export const OrganizationApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Получить информацию об организации по ID
     * @param {number} organizationNo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationControllerGetOrganization: async (organizationNo: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'organizationNo' is not null or undefined
      if (organizationNo === null || organizationNo === undefined) {
        throw new RequiredError('organizationNo', 'Required parameter organizationNo was null or undefined when calling organizationControllerGetOrganization.');
      }
      const localVarPath = `/api/2.0/organization/{organization_no}`
        .replace(`{${'organization_no'}}`, encodeURIComponent(String(organizationNo)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Обновление организации
     * @param {UpdateOrganizationPayloadDto} body
     * @param {number} organizationNo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationControllerUpdateOrganization: async (body: UpdateOrganizationPayloadDto, organizationNo: number, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError('body', 'Required parameter body was null or undefined when calling organizationControllerUpdateOrganization.');
      }
      // verify required parameter 'organizationNo' is not null or undefined
      if (organizationNo === null || organizationNo === undefined) {
        throw new RequiredError('organizationNo', 'Required parameter organizationNo was null or undefined when calling organizationControllerUpdateOrganization.');
      }
      const localVarPath = `/api/2.0/organization/{organization_no}`
        .replace(`{${'organization_no'}}`, encodeURIComponent(String(organizationNo)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      const needsSerialization = (typeof body !== 'string') || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || '');

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Загрузить логотип организации
     * @param {string} image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationControllerUploadLogo: async (image: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'image' is not null or undefined
      if (image === null || image === undefined) {
        throw new RequiredError('image', 'Required parameter image was null or undefined when calling organizationControllerUploadLogo.');
      }
      const localVarPath = `/api/2.0/organization/{organization_no}/logo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      // authentication bearer required

      if (image !== undefined) {
        localVarFormParams.append('image', image as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Получить список организаций в которых числится пользователь
     * @param {Array&lt;string&gt;} [role] Фильтр по ролям
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personControllerGetPersonOrganizations: async (role?: Array<string>, options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/2.0/user/organizations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      if (role) {
        localVarQueryParameter['role'] = role;
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OrganizationApi - functional programming interface
 * @export
 */
export const OrganizationApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Получить информацию об организации по ID
     * @param {number} organizationNo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationControllerGetOrganization(organizationNo: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationResponseDto>> {
      const localVarAxiosArgs = await OrganizationApiAxiosParamCreator(configuration)
        .organizationControllerGetOrganization(organizationNo, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Обновление организации
     * @param {UpdateOrganizationPayloadDto} body
     * @param {number} organizationNo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationControllerUpdateOrganization(body: UpdateOrganizationPayloadDto, organizationNo: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdatePartnerResponseDto>> {
      const localVarAxiosArgs = await OrganizationApiAxiosParamCreator(configuration)
        .organizationControllerUpdateOrganization(body, organizationNo, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Загрузить логотип организации
     * @param {string} image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationControllerUploadLogo(image: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadImageResponseDto>> {
      const localVarAxiosArgs = await OrganizationApiAxiosParamCreator(configuration)
        .organizationControllerUploadLogo(image, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Получить список организаций в которых числится пользователь
     * @param {Array&lt;string&gt;} [role] Фильтр по ролям
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async personControllerGetPersonOrganizations(role?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganizationSearchResponseDto>>> {
      const localVarAxiosArgs = await OrganizationApiAxiosParamCreator(configuration)
        .personControllerGetPersonOrganizations(role, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * OrganizationApi - factory interface
 * @export
 */
export const OrganizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     *
     * @summary Получить информацию об организации по ID
     * @param {number} organizationNo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationControllerGetOrganization(organizationNo: number, options?: any): AxiosPromise<OrganizationResponseDto> {
      return OrganizationApiFp(configuration)
        .organizationControllerGetOrganization(organizationNo, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Обновление организации
     * @param {UpdateOrganizationPayloadDto} body
     * @param {number} organizationNo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationControllerUpdateOrganization(body: UpdateOrganizationPayloadDto, organizationNo: number, options?: any): AxiosPromise<UpdatePartnerResponseDto> {
      return OrganizationApiFp(configuration)
        .organizationControllerUpdateOrganization(body, organizationNo, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Загрузить логотип организации
     * @param {string} image
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationControllerUploadLogo(image: string, options?: any): AxiosPromise<UploadImageResponseDto> {
      return OrganizationApiFp(configuration)
        .organizationControllerUploadLogo(image, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Получить список организаций в которых числится пользователь
     * @param {Array&lt;string&gt;} [role] Фильтр по ролям
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personControllerGetPersonOrganizations(role?: Array<string>, options?: any): AxiosPromise<Array<OrganizationSearchResponseDto>> {
      return OrganizationApiFp(configuration)
        .personControllerGetPersonOrganizations(role, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * OrganizationApi - object-oriented interface
 * @export
 * @class OrganizationApi
 * @extends {BaseAPI}
 */
export class OrganizationApi extends BaseAPI {
  /**
   *
   * @summary Получить информацию об организации по ID
   * @param {number} organizationNo
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApi
   */
  public organizationControllerGetOrganization(organizationNo: number, options?: any) {
    return OrganizationApiFp(this.configuration)
      .organizationControllerGetOrganization(organizationNo, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Обновление организации
   * @param {UpdateOrganizationPayloadDto} body
   * @param {number} organizationNo
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApi
   */
  public organizationControllerUpdateOrganization(body: UpdateOrganizationPayloadDto, organizationNo: number, options?: any) {
    return OrganizationApiFp(this.configuration)
      .organizationControllerUpdateOrganization(body, organizationNo, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Загрузить логотип организации
   * @param {string} image
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApi
   */
  public organizationControllerUploadLogo(image: string, options?: any) {
    return OrganizationApiFp(this.configuration)
      .organizationControllerUploadLogo(image, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Получить список организаций в которых числится пользователь
   * @param {Array&lt;string&gt;} [role] Фильтр по ролям
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationApi
   */
  public personControllerGetPersonOrganizations(role?: Array<string>, options?: any) {
    return OrganizationApiFp(this.configuration)
      .personControllerGetPersonOrganizations(role, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
