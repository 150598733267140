/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * Доброрубль JSON REST API
 *
 * OpenAPI spec version: 2.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '@/api';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { UploadImageResponseDto } from '../models';

/**
 * FtpApi - axios parameter creator
 * @export
 */
export const FtpApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Поддерживаемые расширения: <b>.png</b>
     * @summary Загрузить изображение на CDN
     * @param {string} image
     * @param {string} directory
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ftpControllerUploadImage: async (image: string, directory: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'image' is not null or undefined
      if (image === null || image === undefined) {
        throw new RequiredError('image', 'Required parameter image was null or undefined when calling ftpControllerUploadImage.');
      }
      // verify required parameter 'directory' is not null or undefined
      if (directory === null || directory === undefined) {
        throw new RequiredError('directory', 'Required parameter directory was null or undefined when calling ftpControllerUploadImage.');
      }
      const localVarPath = `/api/2.0/ftp/upload-image/{directory}`
        .replace(`{${'directory'}}`, encodeURIComponent(String(directory)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, 'https://example.com');
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      // authentication bearer required

      if (image !== undefined) {
        localVarFormParams.append('image', image as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.query) {
        query.set(key, options.query[key]);
      }
      localVarUrlObj.search = (new URLSearchParams(query)).toString();
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FtpApi - functional programming interface
 * @export
 */
export const FtpApiFp = function (configuration?: Configuration) {
  return {
    /**
     * Поддерживаемые расширения: <b>.png</b>
     * @summary Загрузить изображение на CDN
     * @param {string} image
     * @param {string} directory
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async ftpControllerUploadImage(image: string, directory: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadImageResponseDto>> {
      const localVarAxiosArgs = await FtpApiAxiosParamCreator(configuration)
        .ftpControllerUploadImage(image, directory, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * FtpApi - factory interface
 * @export
 */
export const FtpApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  return {
    /**
     * Поддерживаемые расширения: <b>.png</b>
     * @summary Загрузить изображение на CDN
     * @param {string} image
     * @param {string} directory
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ftpControllerUploadImage(image: string, directory: string, options?: any): AxiosPromise<UploadImageResponseDto> {
      return FtpApiFp(configuration)
        .ftpControllerUploadImage(image, directory, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * FtpApi - object-oriented interface
 * @export
 * @class FtpApi
 * @extends {BaseAPI}
 */
export class FtpApi extends BaseAPI {
  /**
   * Поддерживаемые расширения: <b>.png</b>
   * @summary Загрузить изображение на CDN
   * @param {string} image
   * @param {string} directory
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FtpApi
   */
  public ftpControllerUploadImage(image: string, directory: string, options?: any) {
    return FtpApiFp(this.configuration)
      .ftpControllerUploadImage(image, directory, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
